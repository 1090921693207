import { AccordionBlok } from '@/components/sb-bloks/ui-kit/AccordionBlok';
import { AnimatedTitleBlok } from '@/components/sb-bloks/ui-kit/AnimatedTitleBlok';
import { ButtonBlok } from '@/components/sb-bloks/ui-kit/ButtonBlok';
import { ButtonCustomBlok } from '@/components/sb-bloks/ui-kit/ButtonCustomBlok';
import { CardBlok } from '@/components/sb-bloks/ui-kit/CardBlok';
import { ColumnBlok } from '@/components/sb-bloks/ui-kit/ColumnBlok';
import { EmbeddedVideoBlok } from '@/components/sb-bloks/ui-kit/EmbeddedVideoBlok';
import { FooterBlok } from '@/components/sb-bloks/ui-kit/FooterBlok';
import { FooterNavColBlok } from '@/components/sb-bloks/ui-kit/FooterNavColBlok';
import { FooterNavItemBlok } from '@/components/sb-bloks/ui-kit/FooterNavItemBlok';
import { GridBlok } from '@/components/sb-bloks/ui-kit/GridBlok';
import { GridItemBlok } from '@/components/sb-bloks/ui-kit/GridItemBlok';
import { HeaderBlok } from '@/components/sb-bloks/ui-kit/HeaderBlok';
import { IconBlok } from '@/components/sb-bloks/ui-kit/IconBlok';
import { ImageBlok } from '@/components/sb-bloks/ui-kit/ImageBlok';
import { ImageIconLinkBlok } from '@/components/sb-bloks/ui-kit/ImageIconLinkBlok';
import { LinkBlok } from '@/components/sb-bloks/ui-kit/LinkBlok';
import { LogoBlok } from '@/components/sb-bloks/ui-kit/LogoBlok';
import { NavColumnBlok } from '@/components/sb-bloks/ui-kit/NavColumnBlok';
import { NavItemBlok } from '@/components/sb-bloks/ui-kit/NavItemBlok';
import { NavTabBlok } from '@/components/sb-bloks/ui-kit/NavTabBlok';
import { PricingCardBlok } from '@/components/sb-bloks/ui-kit/PricingCardBlok';
import { ReviewBadgeBlok } from '@/components/sb-bloks/ui-kit/ReviewBadgeBlok';
import { RichTextBlok } from '@/components/sb-bloks/ui-kit/RichTextBlok';
import { RowBlok } from '@/components/sb-bloks/ui-kit/RowBlok';
import { SectionBlok } from '@/components/sb-bloks/ui-kit/SectionBlok';
import { SliderBlok } from '@/components/sb-bloks/ui-kit/SliderBlok';
import { TableBlok } from '@/components/sb-bloks/ui-kit/TableBlok';
import { TabBlok, TabsBlok } from '@/components/sb-bloks/ui-kit/TabsBlok';
import { TextBlok } from '@/components/sb-bloks/ui-kit/TextBlok';
import { ToggleTabsBlok } from '@/components/sb-bloks/ui-kit/ToggleTabsBlok';

export const uiKitBloks = {
  header: HeaderBlok,
  accordion: AccordionBlok,
  button: ButtonBlok,
  button_custom: ButtonCustomBlok,
  card: CardBlok,
  column: ColumnBlok,
  embedded_video: EmbeddedVideoBlok,
  grid: GridBlok,
  grid_item: GridItemBlok,
  link: LinkBlok,
  icon: IconBlok,
  image: ImageBlok,
  review_badge: ReviewBadgeBlok,
  rich_text: RichTextBlok,
  row: RowBlok,
  section: SectionBlok,
  slider: SliderBlok,
  tab: TabBlok,
  tabs: TabsBlok,
  text: TextBlok,
  toggle_tabs: ToggleTabsBlok,
  pricing_card: PricingCardBlok,
  animated_title: AnimatedTitleBlok,
  generic_table: TableBlok,
  logo: LogoBlok,
  nav_column: NavColumnBlok,
  nav_item: NavItemBlok,
  nav_tab: NavTabBlok,
  footer: FooterBlok,
  footer_nav_column: FooterNavColBlok,
  footer_nav_item: FooterNavItemBlok,
  image_icon_link: ImageIconLinkBlok,
};
