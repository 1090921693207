import type React from 'react';
import { useState } from 'react';
import { css } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import type { SbBlokData } from '@storyblok/js';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import Head from 'next/head';
import { web3Theme } from 'src/theme';

import FlexBox from '@/components/uikit/flex-box/FlexBox';
import { useScreenResize } from '@/hooks';
import type { HeaderStoryblok } from '@/sb-types';
import RenderIf from '@/shared/render-if';

import { NavProvider } from '../../../context/Navigation/useNavContext';

import styles from 'src/components/shared/header/Header.module.scss';

type HeaderBlokProps = {
  blok: SbBlokData<HeaderStoryblok>;
}

const headerStyles = {
  Header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 144px;
    max-width: 1170px;
    margin: 0 auto;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 500;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
      overflow: auto;
      max-height: calc(100vh - 80px);
      width: 100%;
      height: auto;
      padding: 0 20px 48px;
      background: #fff;
      border-bottom-right-radius: 80px;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.10) !important;
      [data-expanded='true'] {
        max-height: 1500px;
      }
    }
  `,
  Navs: css`
    @media (max-width: 1024px) {
      width: 100%;
      height: auto;
      flex-wrap: wrap;
      margin-bottom: var(--space-24);
      position: relative;
    }
  `,
  NavsMobile: css`
    width: 100%;
    height: auto;
    margin: 0;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    @media (min-width: 1024px) {
      [data-expanded='true'] {
        display: none;
      }
    }
  `,
}

export const HeaderBlok: React.FC<HeaderBlokProps> = ({ blok }) => {
  const { width } = useScreenResize();
  const [mobileExpanded, setMobileExpanded] = useState(false);

  const handleMobileMenuClick = () => {
    setMobileExpanded(prevState => !prevState);
  };

  return (
    <header
      { ...blok } { ...storyblokEditable(blok) }
      role="banner"
      className={ classNames(styles.Header, {
        [styles.expanded]: mobileExpanded,
      }) }
      data-expanded={ mobileExpanded }
    >
      <Head>
        <meta
          name="viewport" content="height=device-height,
            width=device-width, initial-scale=1.0,
            maximum-scale=1.0, user-scalable=no" />
      </Head>
      <ThemeProvider theme={ web3Theme }>
        <NavProvider>
          <div className={ styles.Header__Content }>
            { /*Render the mobile view*/ }
            <RenderIf condition={ width < 1024 }>
              <FlexBox
                display="none"
                justifyContent="space-between" alignItems="center"
                className={ styles.Header__MobileHead }
              >
                <FlexBox pb={ '8px' } pr={ '15px' } alignItems={ 'center' }>
                  {
                    blok.logo?.map((nestedBlok) => (<StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
                  }
                </FlexBox>

                <FlexBox gap={ 15 }>
                  {
                    blok.cta?.map((nestedBlok) => (
                      <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
                  }

                  <FlexBox alignItems="center" columnGap={ 24 }>
                    <button
                      data-testid="burger-btn"
                      className={ classNames(styles.Header__MobileHead__MenuIcon, {
                        [styles.menuExpanded]: mobileExpanded,
                      }) }
                      onClick={ handleMobileMenuClick }
                    >
                      <span />
                    </button>
                  </FlexBox>

                </FlexBox>

              </FlexBox>
              <FlexBox
                css={ headerStyles.NavsMobile }
                className={ styles.Header__LeftSide__NavTabsBoxMobile }
                data-expanded={ mobileExpanded }
                gap={ 15 } alignItems={ 'center' }>
                {
                  blok.navTabs?.map((nestedBlok) => (
                    <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
                }
              </FlexBox>
              <FlexBox justifyContent={ 'center' } width={ '100%' }>
                {
                  blok.mobileCta?.map((nestedBlok) => (
                    <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
                }
              </FlexBox>
            </RenderIf>

            { /*Render the desktop view*/ }
            <RenderIf condition={ width > 1024 }>
              <FlexBox>
                <FlexBox padding={ '0 15px 8px 0' } alignItems={ 'center' }>
                  {
                    blok.logo?.map((nestedBlok) => (<StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
                  }
                </FlexBox>
                <FlexBox
                  css={ headerStyles.Navs }
                  gap={ 15 } alignItems={ 'center' }>
                  {
                    blok.navTabs?.map((nestedBlok) => (
                      <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
                  }
                </FlexBox>
                <FlexBox>
                  {
                    blok.cta?.map((nestedBlok) => (
                      <StoryblokComponent blok={ nestedBlok } key={ nestedBlok._uid } />))
                  }
                </FlexBox>
              </FlexBox>
            </RenderIf>
          </div>
        </NavProvider>
      </ThemeProvider>
    </header>
  );
}

