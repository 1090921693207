import type { FC } from 'react';
import { css, Global } from '@emotion/react';

import { FooterBlok } from '@/components/sb-bloks/ui-kit/FooterBlok';
import { HeaderBlok } from '@/components/sb-bloks/ui-kit/HeaderBlok';
import { LinkTrackCtxProvider } from '@/components/uikit/link/TdLink';
import { useScrollHandler } from '@/hooks';
import type { FooterStoryblok, HeaderStoryblok } from '@/sb-types';
import RenderIf from '@/shared/render-if/RenderIf';

import type { BaseLayoutProps } from './BaseLayout/BaseLayout';
import { BaseLayout } from './BaseLayout/BaseLayout';

export interface LayoutProps extends BaseLayoutProps {
  pageName: string;
  pageCategory: string;

  className?: string;
  withHeader?: boolean;
  withFooter?: boolean;
  header?: HeaderStoryblok[];
  footer?: FooterStoryblok[];
}

const marketingBannerHeight = process.env.NEXT_PUBLIC_MARKETING_BANNER_HEIGHT || '0px';
const globalStyles = css`
  :root {
    --marketing-banner-height: ${ marketingBannerHeight };
  }
`;

const styles = {
  Main: ({ withHeader }: Partial<LayoutProps>) => css`
    --padding-top: var(--main-header-height-mobile);

    padding-top: ${ withHeader ? 'var(--padding-top)' : 0 };
  `,
};

export const Layout: FC<LayoutProps> = ({
  children,
  keywords,
  pageCategory,
  pageDesc,
  pageTitle,
  className,
  withHeader = true,
  withFooter = true,
  header,
  footer,
  ...restProps
}) => {
  useScrollHandler(pageCategory);
  return (
    <BaseLayout pageDesc={ pageDesc } pageTitle={ pageTitle } keywords={ keywords }>
      <Global styles={ globalStyles } />
      <RenderIf condition={ withHeader }>
        { header?.map((headerBlok) => (
          <HeaderBlok
            key={ headerBlok._uid } blok={ headerBlok } />
        )) }
      </RenderIf>

      <LinkTrackCtxProvider value={{
        location: 'section',
        category: pageCategory,
      }}>
        <main className={ className || '' } css={ styles.Main({ withHeader }) } { ...restProps }>{ children }</main>
      </LinkTrackCtxProvider>

      <RenderIf condition={ withFooter }>
        <LinkTrackCtxProvider value={{
          location: 'footer',
          category: pageCategory,
        }}>
          { footer?.map((footerBlok) => (
            <FooterBlok
              key={ footerBlok._uid } blok={ footerBlok } />
          )) }
        </LinkTrackCtxProvider>
      </RenderIf>
    </BaseLayout>
  );
};
